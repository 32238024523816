import {
    Grid,
    Text,
    Tooltip,
    Image,
} from "@mantine/core";
import { memo } from "react";
import { Link } from "react-router-dom";
import useSideNavigationStyles from "../styles";

interface PowerbiLinkProps {
    icon: string | undefined,
    name: string | undefined,
    route: string | undefined,
    location: string,
    collapsed: boolean,
    index: number,
    ClickHandler: (e: any, path: string) => void
}

const PowerbiLink = ({ icon, name, route, location, collapsed, index, ClickHandler }: PowerbiLinkProps) => {
    const { classes, cx } = useSideNavigationStyles();

    const WithToolTip = ({ showToolTip, name, children }: any) => {
        return (showToolTip)
            ? <Tooltip label={name} position="right-start" offset={20} withArrow arrowOffset={13} arrowSize={8}>{children}</Tooltip>
            : <>{children}</>
    }

    return (
        <WithToolTip showToolTip={collapsed} name={name}>
            <Link onClick={(e) => ClickHandler(e, `${route}`)} to={`${route}`} style={{ textDecoration: "none" }}>
                <Grid columns={12} key={index} className={
                    route === location
                        ? classes.activeItemWrapper
                        : classes.itemWrapper
                }>
                    {!collapsed && <Grid.Col xs={1}></Grid.Col>}
                    <Grid.Col
                        className={cx(
                            collapsed ? classes.flexCenter : classes.flexStart
                        )}
                        xs={collapsed ? 12 : 3}
                    >
                        <div>
                        <Image
                            src={icon}
                            style={{ width: 25, height: 25 }}
                        />
                        </div>
                    </Grid.Col>
                    {!collapsed && (
                        <>
                            <Grid.Col xs={7} style={{ paddingLeft: 0 }}>
                                <Text c="white" className={cx(classes.privateFiltersText)}>
                                    {name}
                                </Text>
                            </Grid.Col>
                            <Grid.Col xs={1}></Grid.Col>
                        </>
                    )}
                </Grid>
            </Link>
        </WithToolTip>
    );
};

export default PowerbiLink;