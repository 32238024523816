import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { FilterBody, TableMetaData } from "../../features/RaidView";
import { MsalContext } from "@azure/msal-react";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken, getAuthAccessToken } from "../authService";
import { FilterBodyFilters, WidgetCount } from "../../features/RaidView/type";

var fd: FilterBody;
var i: IPublicClientApplication;
var a: AccountInfo[];

export const getRAIDItems = async (
  filterData: FilterBody,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
): Promise<{ data: TableMetaData }> => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;

  fd = filterData;
  i = instance;
  a = accounts;

  return axios({
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/search`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
    data: filterData,
  });
};

export const getRAIDItemsExcelView = async (): Promise<void> => {
  var filterData = fd;
  var accounts = a;
  var instance = i;

  filterData.AllResutlsForPage = true;

  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;

  try {
    // Step 1: Get the raw Excel binary data from the API
    const response = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/search/excel`,
      headers: {
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        authorization: bearer,
      },
      data: filterData,
      responseType: "arraybuffer", // Important to handle binary data
    });

    // Step 2: Create a Blob from the binary data
    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Step 3: Create a download URL for the Blob
    const downloadUrl = window.URL.createObjectURL(blob);

    // Step 4: Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "Items.xlsx"; 
    link.click(); // Programmatically click the link to trigger the download

    // Step 5: Clean up the URL object once done
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading the Excel file:", error);
  }
};

export const getRAIDItemsExcelFull = async (): Promise<void> => {
  var filterData = fd;
  var accounts = a;
  var instance = i;

  filterData.Paging = false;

  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;

  try {
    // Step 1: Get the raw Excel binary data from the API
    const response = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/search/excel`,
      headers: {
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        authorization: bearer,
      },
      data: filterData,
      responseType: "arraybuffer", // Important to handle binary data
    });

    // Step 2: Create a Blob from the binary data
    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Step 3: Create a download URL for the Blob
    const downloadUrl = window.URL.createObjectURL(blob);

    // Step 4: Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "items.xlsx"; // You can customize the file name here
    link.click(); // Programmatically click the link to trigger the download

    // Step 5: Clean up the URL object once done
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading the Excel file:", error);
  }
};

export const getWidgetCount = async (
  payload: WidgetCount,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/count`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
    data: payload,
  });
};
