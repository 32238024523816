import React from "react";
import { WhiteLabelProvider } from "./whiteLabelContext";
import { UserProvider } from "./user.context";
import { CountProvider } from "../contexts/sample.context";
import { FilterProvider } from "./filter.context";
import { ProjectProvider } from "./project.context";
import { RaidListProvider } from "./raidItems.context";
import { AddItemProvider } from "./addItem.context";
import { RefreshRaidListProvider } from "./listViewRefresh.context";
import { AttachmentProvider } from "./attachments.context";
import { NotificationProvider } from "./notifications.context";
import { HistoryProvider } from "./history.context";
import { ViewsProvider } from "./view.context";
import { PowerbiDashboardProvider } from "./powerbiDashboards.context";

const contextProviders = [
  WhiteLabelProvider,
  UserProvider,
  CountProvider,
  FilterProvider,
  RaidListProvider,
  ProjectProvider,
  AddItemProvider,
  RefreshRaidListProvider,
  AttachmentProvider,
  NotificationProvider,
  HistoryProvider,
  ViewsProvider,
  PowerbiDashboardProvider
];

function MultiContextProvider({ children }) {
  const providerHierarchy = contextProviders.reduceRight(
    (nestedProviders, ContextProvider) => (
      <ContextProvider>{nestedProviders}</ContextProvider>
    ),
    children
  );

  return providerHierarchy;
}

export default MultiContextProvider;
