import * as React from "react";
import { getMyPowerbiDashboard,getProjectPowerbiDashboard,getAdminPowerbiDashboard } from "../services/PowerbiDashboardService/PowerbiDashboardService";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { PowerbiEmbedConfig } from "../components/SideNavigation/types";

type Action = 
{ type: "setMyPowerbiDashboard", payload?: PowerbiEmbedConfig } |
{ type: "setProjectPowerbiDashboard", payload?: PowerbiEmbedConfig } |
{ type: "setAdminPowerbiDashboard", payload?: PowerbiEmbedConfig } |
{ type: "setLoadState", payload: "IDEAL" | "LOADED" | "ERROR" | "LOADING" };
type Dispatch = (action: Action) => void;
type State = {
    myPowerbiDashboard: PowerbiEmbedConfig;
    projectPowerbiDashboard: PowerbiEmbedConfig;
    adminPowerbiDashboard: PowerbiEmbedConfig;
    loadState: "IDEAL" | "LOADED" | "ERROR" | "LOADING"
};

type PowerbiDashboardProviderProps = { children: React.ReactNode };

const PowerbiDashboardStateContext = React.createContext<
    { state: State; dispatch: Dispatch } | undefined
>(undefined);

function PowerbiDashboardReducer(state: State, action: Action) {
    switch (action.type) {
        case "setMyPowerbiDashboard": {
            return { ...state, myPowerbiDashboard: action.payload ?? {
                embedUrl: "",
                accessToken: "",
                tiles:[{id:"", title:""}],
                dashboardId:"",
              }
            };
        }
        case "setProjectPowerbiDashboard": {
            return { ...state, projectPowerbiDashboard: action.payload ?? {
                embedUrl: "",
                accessToken: "",
                tiles:[{id:"", title:""}],
                dashboardId:"",
              }
            };
        }
        case "setAdminPowerbiDashboard": {
            return { ...state, adminPowerbiDashboard: action.payload ?? {
                embedUrl: "",
                accessToken: "",
                tiles:[{id:"", title:""}],
                dashboardId:"",
              }
            };
        }
        case "setLoadState": {
            return { ...state, loadState: action.payload };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function PowerbiDashboardProvider({ children }: PowerbiDashboardProviderProps) {
    const [state, dispatch] = React.useReducer(PowerbiDashboardReducer, {
        myPowerbiDashboard: {
            embedUrl: "",
            accessToken: "",
            tiles:[{id:"", title:""}],
            dashboardId:"",
          },
        projectPowerbiDashboard: {
            embedUrl: "",
            accessToken: "",
            tiles:[{id:"", title:""}],
            dashboardId:"",
          },
        adminPowerbiDashboard: {
            embedUrl: "",
            accessToken: "",
            tiles:[{id:"", title:""}],
            dashboardId:"",
          },
        loadState: "IDEAL"
    });
    
    const value = { state, dispatch };
    return (
        <PowerbiDashboardStateContext.Provider value={value}>
            {children}
        </PowerbiDashboardStateContext.Provider>
    );
}

async function updatePowerbiDashboardsAsync(dispatch: Dispatch, action: Action | null, instance: IPublicClientApplication, accounts: AccountInfo[]) {
    try {
        dispatch({type:"setLoadState", payload: "LOADING"})
        if (action?.type == "setMyPowerbiDashboard") {
            const powerbiMyDashboard = await getMyPowerbiDashboard(instance, accounts);
            dispatch({ type: "setMyPowerbiDashboard", payload: powerbiMyDashboard.data });
        } else if (action?.type == "setProjectPowerbiDashboard") {
            const powerbiProjectDashboard = await getProjectPowerbiDashboard(instance, accounts);
            dispatch({ type: "setProjectPowerbiDashboard", payload: powerbiProjectDashboard.data });
        }else if (action?.type == "setAdminPowerbiDashboard") {
            const powerbiAdminDashboard = await getAdminPowerbiDashboard(instance, accounts);
            dispatch({ type: "setAdminPowerbiDashboard", payload: powerbiAdminDashboard.data });
        } else {
            const powerbiMyDashboard = await getMyPowerbiDashboard(instance, accounts);
            dispatch({ type: "setMyPowerbiDashboard", payload: powerbiMyDashboard.data });
        }
        dispatch({type:"setLoadState", payload: "LOADED"})
    } catch (error) {
        dispatch({type:"setLoadState", payload: "ERROR"})
     }
}


function usePowerbiDashboards() {
    const context = React.useContext(PowerbiDashboardStateContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a CountProvider - powerbi");
    }
    return context;
}

export { PowerbiDashboardProvider, usePowerbiDashboards, updatePowerbiDashboardsAsync };